<script lang="ts" setup>
const props = defineProps({
  title: {
    type: String,
    default: "MORE+",
  },
  link: {
    type: String,
    default: "#",
  },
});
</script>

<template>
  <nuxt-link :to="props.link" class="link" target="_blank">{{ props.title }}</nuxt-link>
</template>

<style lang="scss" scoped>
.link {
  display: inline-block;
  border-radius: 50px;
  border: 1px solid #000;
  padding: 6px 30px;
  font-size: 11px;
  font-family: $font-NotoSans;
  line-height: 1.5;
  color: rgba(#000, 0.8);

  @include max-media(480) {
    font-size: 7px;
  }

  &:hover {
    background-color: #000;
    color: #fff;
  }

  &.active {
    background-color: #000;
    color: #fff;
  }
}
</style>
